import request from '@/utils/request'

export function findUsers(params) {
  return request({
    url: `/users`,
    method: 'get',
    params: params
  })
}

// 查询登录用户基本信息
export function findUserBasicInfo(userId) {
  return request({
    url: `/users/${userId}/basic_info`,
    method: 'get'
  })
}

// 更新账户的username
export function updateUserSelfUsername(userId, data) {
  return request({
    url: `users/${userId}/username`,
    method: 'patch',
    data
  })
}

// 更新账户的姓名
export function updateUserSelfFullName(userId, data) {
  return request({
    url: `users/${userId}/full_name`,
    method: 'patch',
    data
  })
}

// 更新账户的密码
export function updateUserSelfPassword(userId, data) {
  return request({
    url: `users/${userId}/password`,
    method: 'patch',
    data
  })
}

// 更新账户的绑定手机
export function updateUserSelfPhoneNumber(userId, data) {
  return request({
    url: `users/${userId}/phone_number`,
    method: 'patch',
    data
  })
}

// 更新账户的绑定邮箱
export function updateUserSelfEmail(userId, data) {
  return request({
    url: `users/${userId}/email`,
    method: 'patch',
    data
  })
}

// 验证手机号是否已被注册
export function verifyRegisterPhoneNumber(data) {
  return request({
    url: `/register/verify_phone_number`,
    method: 'post',
    data
  })
}

// 获取有注册账号跟踪权限的有效销售
export function findCanTrackRegisteredAccountSaleOptions() {
  return request({
    url: `/sales/track_registered_account/options`,
    method: 'get'
  })
}

// 查询用户的手机号码
export function findUserEncryptedPhoneNumber(userId) {
  return request({
    url: `/users/${userId}/encrypted_phone_number`,
    method: 'get'
  })
}

<template>
  <div>
    <a-row class="user-info">
      <a-col :span="24">
        <a-descriptions title="登录账号" :column="2">
          <a-descriptions-item :span="1">
            <div class="iotplt-word-break-all">{{ user.username }}</div>
          </a-descriptions-item>
          <a-descriptions-item>
            <a @click="showEditUsernameModal()">编辑</a>
          </a-descriptions-item>
        </a-descriptions>
        <a-divider />
        <a-descriptions title="姓名" :column="2">
          <a-descriptions-item :span="1">
            <div class="iotplt-word-break-all">{{ user.full_name }}</div>
          </a-descriptions-item>
          <a-descriptions-item>
            <a @click="showEditFullNameModal()">编辑</a>
          </a-descriptions-item>
        </a-descriptions>
        <a-divider />
        <a-descriptions title="密码" :column="2">
          <a-descriptions-item :span="1">
            已设置
          </a-descriptions-item>
          <a-descriptions-item>
            <a @click="showVerifySmsCaptchaModal('user_edit_password')">编辑</a>
          </a-descriptions-item>
        </a-descriptions>
        <a-divider />
        <a-descriptions title="绑定手机" :column="2">
          <a-descriptions-item :span="1">
            {{ user.phone_number }}
          </a-descriptions-item>
          <a-descriptions-item>
            <a @click="showVerifySmsCaptchaModal('ser_edit_phone_number')">编辑</a>
          </a-descriptions-item>
        </a-descriptions>
        <a-divider />
        <a-descriptions title="绑定邮箱" :column="2">
          <a-descriptions-item :span="1">
            {{ user.email ? user.email : '未绑定' }}
          </a-descriptions-item>
          <a-descriptions-item>
            <a @click="showEditEmailModal()">编辑</a>
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
    <!-- 编辑用户名 -->
    <edit-username
      v-if="isShowEditUserNameModal"
      :visible.sync="isShowEditUserNameModal"
      :id="userId"
      @completed="fetchData"
    />
    <!-- 编辑姓名 -->
    <edit-full-name
      v-if="isShowEditFullNameModal"
      :visible.sync="isShowEditFullNameModal"
      :id="userId"
      @completed="fetchData"
    />
    <!-- 校验手机验证码 -->
    <verify-sms-captcha
      v-if="isShowVerifySmsCaptchaModal"
      :visible.sync="isShowVerifySmsCaptchaModal"
      :id="userId"
      :identification="identification"
      @completed="showNextModal"
    />
    <!-- 编辑密码 -->
    <edit-password
      v-if="isShowEditPasswordModal"
      :visible.sync="isShowEditPasswordModal"
      :identification="identification"
      :next-verify-id="nextVerifyId"
      :id="userId"
      @completed="fetchData"
    />
    <!-- 编辑绑定手机 -->
    <edit-phone-number
      v-if="isShowEditPhoneNumberModal"
      :visible.sync="isShowEditPhoneNumberModal"
      :id="userId"
      :identification="identification"
      :next-verify-id="nextVerifyId"
      @completed="fetchData"
    />
    <!-- 编辑绑定邮箱 -->
    <edit-email
      v-if="isShowEditEmailModal"
      :visible.sync="isShowEditEmailModal"
      :id="userId"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import { findUserBasicInfo } from '@/api/user'
export default {
  name: 'UserInfo',
  components: {
    EditUsername: () => import('@/views/users/personal_info/EditUsernameModal'),
    EditFullName: () => import('@/views/users/personal_info/EditFullNameModal'),
    VerifySmsCaptcha: () => import('@/views/users/personal_info/VerifySmsCaptchaModal'),
    EditPassword: () => import('@/views/users/personal_info/EditPasswordModal'),
    EditPhoneNumber: () => import('@/views/users/personal_info/EditPhoneNumberModal'),
    EditEmail: () => import('@/views/users/personal_info/EditEmailModal')
  },
  data() {
    return {
      user: {},
      identification: '',
      isShowEditUserNameModal: false,
      isShowEditFullNameModal: false,
      isShowVerifySmsCaptchaModal: false,
      isShowEditPasswordModal: false,
      isShowEditPhoneNumberModal: false,
      isShowEditEmailModal: false,
      nextVerifyId: ''
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    userId() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    showEditUsernameModal() {
      this.isShowEditUserNameModal = true
    },

    showEditFullNameModal() {
      this.isShowEditFullNameModal = true
    },

    showVerifySmsCaptchaModal(identification) {
      this.identification = identification
      this.isShowVerifySmsCaptchaModal = true
    },

    showNextModal(e) {
      this.nextVerifyId = e.nextVerifyId
      if (e.identification === 'user_edit_password') {
        this.isShowEditPasswordModal = true
      } else {
        this.isShowEditPhoneNumberModal = true
      }
    },

    showEditEmailModal() {
      this.isShowEditEmailModal = true
    },

    fetchData() {
      findUserBasicInfo(this.userId).then((res) => {
        this.user = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user-info{
  padding: 24px;
  width: 40%;
  background: #fff;
  display: flex;
  margin: auto;
}
</style>

<template>
  <div>
    <user-info />
  </div>
</template>

<script>
import UserInfo from '@/views/users/personal_info/UserInfo.vue'
export default {
  components: {
    UserInfo
  }
}
</script>
